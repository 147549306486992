.footer-container{
    background-color: #242424;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-subscription{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
}

.footer-subscription > p{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading{
    margin-bottom: 24px;
    font-size: 24px;
}

.footer-subscription-text{
    margin-bottom: 24px;
    font-size: 20px;
}

.footer-input{
    padding: 10px 20px;
    border-radius: 2px;
    margin-right: 10px;
    border: none;
    outline: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
}

.footer-links{
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
}

.footer-link-wrapper{
    display: flex;
}

.footer-link-items{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
    color: #fff;
}

.footer-link-items > h2 {
    margin-bottom: 16px;
}

.footer-link-items{
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;
}

.footer-link-items a{
    color: #fff;
    text-decoration: none;
    margin-bottom: 8px;
    font-size: 14px;
}

.footer-link-items a:hover{
    color: #01bf71;
    transition: 0.3s ease-out;
}

.footer-email-form h2 {
    margin-bottom: 32px;
}

.footer-input::placeholder {
    color: #b1b1b1;
}

/*social icons*/

.social-icon-link{
    color: #fff;
    font-size: 24px;
}

.social-media{
    max-width: 1000px;
    width: 100%;
}
.social-media img {
    width: 180px;
}

.social-media-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
}

.social-icons{  
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
}

.social-logo{
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.website-rights{
    color: #fff;
    margin-bottom: 16px;
}

@media screen and (max-width: 820px){
    .footer-links{
        padding-top: 32px;
    }

    .footer-input{
        width: 100%;
    }

    .btn {
        width: 100%;
    }

    .footer-link-wrapper {
        flex-direction: column;
    }

    .social-media-wrap {
        flex-direction: column;
    }
}